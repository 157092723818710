import {
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useContext, useEffect, useRef } from "react";
import CustomButton from "../../common/Button/CustomButton";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Grid2 from "@mui/material/Unstable_Grid2/";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
// Assets
import AddProductImage from "../../../assets/images/placeholder/add-product.jpg";
// Firebase
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { storage } from "../../../firebase";
import "./styles.scss";

// Image Libraries

import FileUpload from "../../common/FileUpload";
import CompressAPI from "../../../api/compressImage/CompressAPI";
import AddProductContext from "../../../context/addProduct/AddProductContext";
import AllCategoriesContext from "../../../context/allCategories/AllCategoriesContext";
import ProductsAPI from "../../../api/firebase/ProductsAPI";

// Alert Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const AddProduct = ({ hideModal, handleSetProduct, action }) => {
  const parentCategoryRef = useRef(null);
  const titleRef = useRef(null);
  const maxPriceRef = useRef(null);
  const listPriceRef = useRef(null);
  const unitsInStockRef = useRef(null);
  const netQuantityRef = useRef(null);
  const unitTypeRef = useRef(null);
  const descriptionRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const productContext = useContext(AddProductContext);
  const allCategoriesContext = useContext(AllCategoriesContext);
  const allCategoriesArr = allCategoriesContext.state;

  const [selSubCategories, setSelSubCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isAddingRatings, setIsAddingRatings] = useState(false);

  const productsAPI = new ProductsAPI();
  console.log("allCategoriesArr:", allCategoriesArr);
  console.log("productContext:", productContext.state);

  const parentCategories = allCategoriesArr.filter((cat) => {
    return cat.parentCategory.id === "None";
  });
  let initProImages = [];
  //if (action === "EDIT") {
  initProImages = productContext.state.images;
  //}
  const [imageData, setImageData] = useState(initProImages);
  const [progress, setProgress] = useState(0);
  const titleInputLimit = {
    minLength: 5,
    maxLength: 200,
  };
  const priceInputLimit = {
    min: 0,
    max: 100000,
  };
  const unitsInStockInputLimit = {
    min: 0,
    max: 10000,
  };
  useEffect(() => {
    const _selSubCategories = productContext.state.categories;
    setSelSubCategories(_selSubCategories);
    const selCatId = productContext.state.parentCategory.id;
    const _subCategories = allCategoriesArr.filter(
      (e) => e.parentCategory.id === selCatId
    );
    setSubCategories(_subCategories);
  }, []);

  // use effect errorMsg change

  useEffect(() => {
    if (errorMsg !== "") {
      setOpenErrorDialog(true);
    } else {
      setOpenErrorDialog(false);
    }
  }, [errorMsg]);

  //close open error dialog
  const handleCloseErrorDialog = () => {
    setErrorMsg("");
  };
  const handleChangeCategory = (event) => {
    setSelSubCategories([]);
    const selCatId = event.target.value;
    if (selCatId === "NONE") {
      productContext.setState({
        ...productContext.state,
        parentCategory: {
          id: "NONE",
          name: "None",
        },
      });
      setSubCategories([]);
      return;
    }
    // Get Category detail
    const categoryDetail = parentCategories.find((e) => e.id === selCatId);
    // find categories from allCategoriesArray, where parentCategory is selCatId
    const subCategoriesArr = allCategoriesArr.filter(
      (e) => e.parentCategory.id === selCatId
    );
    // set subCategoriesArr to state
    setSubCategories(subCategoriesArr);
    productContext.setState({
      ...productContext.state,
      parentCategory: {
        id: categoryDetail.id,
        name: categoryDetail.name,
        images: categoryDetail.images,
        showInNavbar: categoryDetail.showInNavbar,
      },
    });
  };
  const handleChangeSubcategory = (event) => {
    // Get Parent Category
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const tempSubcategories =
      typeof value === "string" ? value.split(",") : value;

    setSelSubCategories(tempSubcategories);
    productContext.setState({
      ...productContext.state,
      categories: tempSubcategories,
    });
  };
  function handleFormData(formData) {
    productContext.setState({ ...productContext.state, ...formData });
  }
  const handleAddProductClick = () => {
    setErrorMsg("");
    // validate productContext
    if (productContext.state.parentCategory.id === "NONE") {
      parentCategoryRef.current.focus();
      setErrorMsg("Please select Parent Category");
      return;
    }
    if (productContext.state.title.length < 5) {
      titleRef.current.focus();
      setErrorMsg("Title must be at least 5 characters");
      return;
    }
    if (Number(productContext.state.listPrice) < 1) {
      listPriceRef.current.focus();
      setErrorMsg("List Price must be greater than 0");
      return;
    }
    if (Number(productContext.state.maxPrice) < 1) {
      maxPriceRef.current.focus();
      setErrorMsg("Max Price must be greater than 0");
      return;
    }
    if (
      Number(productContext.state.listPrice) >
      Number(productContext.state.maxPrice)
    ) {
      listPriceRef.current.focus();
      setErrorMsg("List Price must be less than or equal to MRP.");
      return;
    }
    if (Number(productContext.state.unitsInStock) < 1) {
      unitsInStockRef.current.focus();
      setErrorMsg("Units In Stock must be greater than 0");
      return;
    }
    if (Number(productContext.state.netQuantity) < 1) {
      netQuantityRef.current.focus();
      setErrorMsg("Net Quantity must be greater than 0");
      return;
    }
    if (productContext.state.unitType === "") {
      unitTypeRef.current.focus();
      setErrorMsg("Please select unit type");
      return;
    }

    if (productContext.state.images.length === 0) {
      setErrorMsg("Please provide atleast one image for product");
      return;
    }
    if (productContext.state.description.length < 10) {
      setErrorMsg("Please provide description (atleast 10 characters)");
      return;
    }
    console.log("action:", action, " productContext:", productContext.state);
    handleSetProduct(action, productContext.state.id);
  };
  function handleImageChange(e) {
    setProgress(0);
    setIsUploading(true);
    const upImage = e.target.files[0];
    if (upImage) {
      // console.log("image:", upImage);
      // Compress Image and get BLOB
      const compressProps = {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 900, // the max width of the output image, defaults to 1920px
        maxHeight: 900, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
        rotate: true, // Enables rotation, defaults to false
      };
      const imgCompressor = new CompressAPI();
      const req = imgCompressor.compressImage(upImage, compressProps);
      req
        .then((resData) => {
          console.log("Image resData:", resData);
          if (!resData.success) {
            setIsUploading(false);
            return;
          }
          const imgBlob = resData.data;
          //console.log("compressed img blob data:", imgBlob);
          // Set blob data to images state

          ///// Convert base64 to file /////////
          const fileName = "IMG_" + Date.now() + "_" + imgBlob.alt;
          const base64str = imgBlob.data;
          const imgExt = imgBlob.ext;
          setImageData((prevState) => {
            if (prevState) {
              return [...prevState, { ...imgBlob, name: fileName }];
            } else {
              return imgBlob;
            }
          });
          const reqData = imgCompressor.base64ToImage(base64str, imgExt);
          if (!reqData.success) {
            setIsUploading(false);
            return;
          }
          const file = reqData.data;
          handleFileUpload({
            path: "images/products/",
            file,
            fileName,
          });
        })
        .catch((ex) => {
          setIsUploading(false);
          console.log("CompressImage Error:", ex);
        });
    }
  }
  const handleFileUpload = ({ path, file, fileName }) => {
    // console.log("path:", path, " file:", file);
    fileName = fileName.replace(/[()]/g, "");
    const storageRef = ref(storage, `${path}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log("Upload is " + progress + "% done");
        setProgress(prog);
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
            break;
        }
      },
      (error) => {
        setIsUploading(false);
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized": // User doesn't have permission to access the object
            console.log("Upload Error: unauthorized access");
            alert("Upload Error: unauthorized access");
            break;
          case "storage/canceled": // User canceled the upload
            console.log("Upload Error: Upload is cancelled");
            alert("Upload Error: Upload is cancelled");
            break;
          case "storage/unknown": // Unknown error occurred, inspect error.serverResponse
            console.log("Upload Error: Unknown error occurred");
            alert("Upload Error: Unknown error occurred");
            break;
          default:
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref)
          .then((downloadURL) => {
            console.log("File available at", downloadURL);
            setIsUploading(false);
            productContext.setState({
              ...productContext.state,
              images: [
                ...productContext.state.images,
                { name: fileName, url: downloadURL },
              ],
            });
            const pro_id = productContext.state.id;
            if (pro_id !== "") {
              // Update Image url in database product(If added)
              productsAPI.getProductById(pro_id).then((res) => {
                console.log("getProductDetailById RES:", res);
                if (res.success) {
                  // update images urls
                  const dataToSend = {
                    id: pro_id,
                    payload: {
                      images: [
                        ...productContext.state.images,
                        { name: fileName, url: downloadURL },
                      ],
                    },
                  };
                  productsAPI.setProduct(dataToSend, false);
                }
              });
            }
          })
          .catch((ex) => {
            setIsUploading(false);
            alert("Error while fetching image url: ", ex);
          });
      }
    );
  };
  const handleDeleteFile = (index, fileName) => {
    console.log("delete file:", index, " name:", fileName);
    const tempImageData = [...imageData];
    tempImageData.splice(index, 1);
    setImageData(tempImageData);
    const fileRef = ref(storage, `images/products/${fileName}`);
    // Delete the file
    deleteObject(fileRef)
      .then(() => {
        // File deleted successfully
        console.log("deleted successfully");
        const updatedURLs = productContext.state.images.filter(
          (state) => state.name !== fileName
        );
        productContext.setState({
          ...productContext.state,
          images: updatedURLs,
        });
        const pro_id = productContext.state.id;
        if (pro_id !== "") {
          // Update Image url in database product(If added)
          productsAPI.getProductById(pro_id).then((res) => {
            console.log("getProductDetailById RES:", res);
            if (res.success) {
              // update images urls
              const dataToSend = {
                id: pro_id,
                payload: {
                  images: updatedURLs,
                },
              };
              productsAPI.setProduct(dataToSend, false);
            }
          });
        }
      })
      .catch((err) => {
        // Uh-oh, an error occurred!
        console.log("Error while deleting image: ", err);
        const updatedURLs = productContext.state.images.filter(
          (state) => state.name !== fileName
        );
        productContext.setState({
          ...productContext.state,
          images: updatedURLs,
        });
        const pro_id = productContext.state.id;
        if (pro_id !== "") {
          // Update Image url in database product(If added)
          productsAPI.getProductById(pro_id).then((res) => {
            console.log("getProductDetailById RES:", res);
            if (res.success) {
              // update images urls
              const dataToSend = {
                id: pro_id,
                payload: {
                  images: updatedURLs,
                },
              };
              productsAPI.setProduct(dataToSend, false);
            }
          });
        }
      });
  };
  const addRatings = () => {
    if (window.confirm("Sure to add ratings.") === false) {
      return;
    }
    setIsAddingRatings(true);
    const min5 = 60;
    const max5 = 100;
    const min4 = 5;
    const max4 = 8;
    const min3 = 2;
    const max3 = 5;
    const min2 = 1;
    const max2 = 3;
    const min1 = 0;
    const max1 = 2;
    const total5StarRatings = Math.floor(Math.random() * (max5 - min5)) + min5;
    const total4StarRatings = Math.floor(Math.random() * (max4 - min4)) + min4;
    const total3StarRatings = Math.floor(Math.random() * (max3 - min3)) + min3;
    const total2StarRatings = Math.floor(Math.random() * (max2 - min2)) + min2;
    const total1StarRatings = Math.floor(Math.random() * (max1 - min1)) + min1;
    // const total1StarRatings = 0;
    let ratingArr = [];
    for (let index = 0; index < total5StarRatings; index++) {
      ratingArr.push(5);
    }
    for (let index = 0; index < total4StarRatings; index++) {
      ratingArr.push(4);
    }
    for (let index = 0; index < total3StarRatings; index++) {
      ratingArr.push(3);
    }
    for (let index = 0; index < total2StarRatings; index++) {
      ratingArr.push(2);
    }
    for (let index = 0; index < total1StarRatings; index++) {
      ratingArr.push(1);
    }
    console.log("ratingArr:", ratingArr);
    productsAPI.setProduct({
      id: productContext.state.id,
      payload: {
        ratings: ratingArr,
      },
    });

    alert("Ratings added successfully!!");
    setIsAddingRatings(false);
  };
  return (
    <Grid2 container spacing={2}>
      {/* show error message */}
      {errorMsg !== "" && <Grid2 xs={12}>{errorMsg}</Grid2>}
      <Grid2 xs={12}>
        <FormControl fullWidth>
          <InputLabel id="category">Select category</InputLabel>
          <Select
            inputRef={parentCategoryRef}
            labelId="category"
            id="category"
            value={productContext.state.parentCategory.id}
            label="Select category"
            onChange={handleChangeCategory}
          >
            <MenuItem value="NONE">Select category</MenuItem>
            {parentCategories.map((data) => {
              return (
                <MenuItem key={data.id} value={data.id}>
                  {data.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid2>
      {subCategories.length > 0 && (
        <Grid2 xs={12}>
          <FormControl fullWidth>
            <Select
              multiple
              displayEmpty
              value={selSubCategories}
              onChange={handleChangeSubcategory}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Select Sub category";
                }
                return selected.join(", ").replace(/_/g, " ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {subCategories.map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  <Checkbox checked={selSubCategories.indexOf(cat.id) > -1} />
                  <ListItemText primary={cat.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>
      )}
      <Grid2 xs={12}>
        <TextField
          fullWidth
          id="id"
          label="Product Id or SKU (from amazon, flipkart or others, if any)"
          variant="standard"
          value={productContext.state.id}
          onChange={(e) => {
            handleFormData({ id: e.target.value });
          }}
          inputProps={titleInputLimit}
          disabled={action === "ADD" ? false : true}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextField
          inputRef={titleRef}
          required
          fullWidth
          id="title"
          label="Title"
          variant="standard"
          value={productContext.state.title}
          onChange={(e) => {
            handleFormData({ title: e.target.value });
          }}
          inputProps={titleInputLimit}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextField
          fullWidth
          id="flipkart_url"
          label="Flipkart Url"
          variant="standard"
          value={productContext.state.flipkartURL}
          onChange={(e) => {
            handleFormData({ flipkartURL: e.target.value });
          }}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextField
          fullWidth
          id="amazon_url"
          label="Amazon Url"
          variant="standard"
          value={productContext.state.amazonURL}
          onChange={(e) => {
            handleFormData({ amazonURL: e.target.value });
          }}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextField
          fullWidth
          id="tata1mg_url"
          label="Tata 1mg Url"
          variant="standard"
          value={productContext.state.tata1mgURL}
          onChange={(e) => {
            handleFormData({ tata1mgURL: e.target.value });
          }}
        />
      </Grid2>
      <Grid2 xs={12}>
        <TextField
          fullWidth
          id="searchTerms"
          label="Search terms"
          variant="standard"
          value={productContext.state.searchTerms}
          onChange={(e) => {
            handleFormData({ searchTerms: e.target.value });
          }}
          inputProps={titleInputLimit}
        />
      </Grid2>
      <Grid2 xs={4}>
        <TextField
          inputRef={maxPriceRef}
          required
          fullWidth
          type="number"
          name="maxPrice"
          id="maxPrice"
          label="MRP"
          variant="standard"
          value={productContext.state.maxPrice}
          onChange={(e) => handleFormData({ maxPrice: Number(e.target.value) })}
          inputProps={priceInputLimit}
        />
      </Grid2>
      <Grid2 xs={4}>
        <TextField
          inputRef={listPriceRef}
          required
          fullWidth
          type="number"
          name="listPrice"
          id="listPrice"
          label="List Price (Your selling price)"
          variant="standard"
          value={productContext.state.listPrice}
          onChange={(e) =>
            handleFormData({ listPrice: Number(e.target.value) })
          }
          inputProps={priceInputLimit}
        />
      </Grid2>
      <Grid2 xs={4}>
        <TextField
          inputRef={unitsInStockRef}
          required
          fullWidth
          type="number"
          name="unitsInStock"
          id="unitsInStock"
          label="Units (In stock)"
          variant="standard"
          value={productContext.state.unitsInStock}
          onChange={(e) =>
            handleFormData({ unitsInStock: Number(e.target.value) })
          }
          inputProps={unitsInStockInputLimit}
        />
      </Grid2>
      <Grid2 xs={3}>
        <TextField
          inputRef={netQuantityRef}
          required
          fullWidth
          type="number"
          name="netQuantity"
          id="netQuantity"
          label="Net Quantity"
          variant="standard"
          value={productContext.state.netQuantity}
          onChange={(e) =>
            handleFormData({ netQuantity: Number(e.target.value) })
          }
          inputProps={priceInputLimit}
        />
      </Grid2>
      <Grid2 xs={3}>
        {/* <FormControl fullWidth>
          <InputLabel id="unitOfQuantity">Select unit</InputLabel>
          <Select
            labelId="unitOfQuantity"
            id="unitOfQuantity"
            value={productContext.state.parentCategory.id}
            label="Select unit of quantity"
            onChange={handleChangeCategory}
          >
            <MenuItem value="NONE">Select unit</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Unit type
          </InputLabel>
          <Select
            inputRef={unitTypeRef}
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={productContext.state.unitType}
            onChange={(e) => handleFormData({ unitType: e.target.value })}
            label="Unit"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Grams">Grams</MenuItem>
            <MenuItem value="Kilograms">Kilograms</MenuItem>
            <MenuItem value="Litres">Litres</MenuItem>
            <MenuItem value="Milliliters">Milliliters</MenuItem>
            <MenuItem value="Set">Set</MenuItem>
            <MenuItem value="Pack">Pack</MenuItem>
            <MenuItem value="Piece">Piece</MenuItem>
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 xs={12}>
        {imageData.map((img, index) => {
          const bgImg =
            img.url !== undefined
              ? `url(${img.url})`
              : `url(${img.prefix}${img.data})`;
          // console.log("img.url:", img.url, "__ bg img:", bgImg);
          return (
            <label className="uploaded-product" key={index}>
              <Paper
                style={{
                  backgroundImage: bgImg,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Tooltip title="Delete">
                  <IconButton
                    className="action-button"
                    aria-label="delete"
                    onClick={() => {
                      if (window.confirm("Sure to delete?"))
                        handleDeleteFile(index, img.name);
                    }}
                  >
                    <DeleteIcon className="action-icons delete-icon" />
                  </IconButton>
                </Tooltip>
              </Paper>
            </label>
          );
        })}
        <FileUpload
          acceptFileType="image/*"
          handleChange={handleImageChange}
          bgImageSrc={AddProductImage}
          upProgress={progress}
        />
      </Grid2>
      <Grid2 xs={12}>
        <p>Description</p>
        <CKEditor
          inputRef={descriptionRef}
          editor={ClassicEditor}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            editor.setData(productContext.state.description);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            handleFormData({ description: data });
          }}
        />
      </Grid2>
      {action === "EDIT" && (
        <CustomButton
          variant="outlined"
          color="warning"
          size="small"
          fullWidth
          sx={{ marginTop: "15px" }}
          onClick={addRatings}
          disabled={isAddingRatings}
        >
          Add Ratings
        </CustomButton>
      )}

      <CustomButton
        variant="contained"
        color="primary"
        size="small"
        onClick={handleAddProductClick}
        fullWidth
        sx={{ marginTop: "15px" }}
        disabled={isUploading || productContext.state.title.length < 5}
      >
        {isUploading
          ? "Uploading image"
          : action === "ADD"
          ? "Add Product"
          : "Update Product"}
      </CustomButton>
      {action === "EDIT" && (
        <CustomButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={hideModal}
          fullWidth
          sx={{ marginTop: "15px" }}
        >
          Cancel
        </CustomButton>
      )}
      <Dialog
        open={openErrorDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseErrorDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {errorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Grid2>
  );
};

export default AddProduct;
