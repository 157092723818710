import {Container, Typography} from "@mui/material";
import React from "react";

const ShippingPolicy = () => {
  return (
    <Container maxWidth={false}>
      <Typography variant="h4" mt="2rem">
        Shipping Policy
      </Typography>
      <Typography variant="h5" mt="2rem">
        1. Shipping Methods and Timeframes
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        We offer various shipping methods to ensure your order reaches you in a
        timely and efficient manner. The available shipping options and
        estimated delivery timeframes will be presented during the checkout
        process. Please note that delivery times may vary based on the shipping
        method selected and the destination of the package
      </Typography>
      <Typography variant="h5" mt="2rem">
        2. Order Processing Time
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        Upon receiving your order, we will process and ship it as quickly as
        possible. Order processing typically takes 1-2 business days, excluding
        weekends and holidays. In case of any delays or issues with your order,
        our customer support team will reach out to you promptly
      </Typography>
      <Typography variant="h5" mt="2rem">
        3. Shipping Charges
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        Shipping costs will be calculated based on the shipping method, package
        weight, and destination. The shipping charges will be displayed during
        the checkout process, allowing you to review and accept them before
        completing your purchase.
      </Typography>
      <Typography variant="h5" mt="2rem">
        4. Tracking Information
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        Once your order is shipped, you will receive a shipping confirmation
        email containing the tracking details. This information will allow you
        to monitor the status and location of your package in real-time.
      </Typography>
      <Typography variant="h5" mt="2rem">
        5. Undeliverable Packages
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        In case a package is deemed undeliverable due to incorrect shipping
        information provided by the customer, refusal of delivery, or failure to
        pick up the package from the carrier's location within the specified
        time, the customer will be responsible for any additional shipping
        charges to reship the package.
      </Typography>
      <Typography variant="h5" mt="2rem">
        6. Lost or Damaged Packages
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        In the unfortunate event of a lost or damaged package during transit,
        please contact our customer support immediately. We will work with the
        carrier to resolve the issue and initiate a replacement or refund,
        depending on the circumstances.
      </Typography>
      <Typography variant="h5" mt="2rem">
        7. Address Changes
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        If you need to make changes to your shipping address after placing an
        order, please contact our customer support as soon as possible. We will
        do our best to accommodate the changes if the order has not already been
        processed for shipping.
      </Typography>
      <Typography variant="h5" mt="2rem">
        8. Holidays and Peak Seasons
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        During holidays and peak seasons, such as Black Friday or Christmas,
        shipping carriers may experience higher volumes and potential delays.
        While we strive to get your orders to you as quickly as possible, we
        recommend placing your orders well in advance to ensure timely delivery
        during such periods.
      </Typography>
      <Typography variant="h5" mt="2rem">
        9. Free Shipping Offers
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        Occasionally, we may offer free shipping promotions. Please note that
        the terms and conditions of these offers will be clearly stated, and
        they may have specific eligibility criteria, such as a minimum order
        value or delivery destination restrictions.
      </Typography>
      <Typography variant="h5" mt="2rem">
        10. Policy Updates
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        This shipping policy may be updated from time to time to reflect changes
        in our shipping procedures or regulations. The most recent version will
        be available on our website, and any significant updates will be
        communicated to customers through email or other appropriate channels.
      </Typography>

      <Typography variant="body1" mt="2rem">
        If you have any further questions or need assistance with your order or
        shipping, please don't hesitate to email us at support@inproveda.com or
        call on +91-9411174186. We are here to help and ensure you have a
        pleasant shopping experience with us.
      </Typography>
    </Container>
  );
};

export default ShippingPolicy;
