import {Typography} from "@mui/material";
import {Box} from "@mui/system";
import React from "react";
import CategoryBox from "./CategoryBox";
const CategoriesContainer = ({
  heading = "What are you looking for ?",
  categories = [],
  view = "RECTANGLE",
}) => {
  return (
    <div>
      <Typography
        variant="h5"
        component="div"
        sx={{mt: "2rem", mb: "1rem", textAlign: "center"}}
        className="wow fadeIn"
      >
        {heading}
      </Typography>
      <Box className="category-list">
        {categories.length > 0 &&
          categories.map((category, index) => {
            return (
              <CategoryBox
                key={index}
                view={view}
                title={category.name}
                imgSrc={
                  category.images[0] !== undefined ? category.images[0].url : ""
                }
                link={`/collections/` + category.id}
              />
            );
          })}
        {categories.length === 0 &&
          [0, 1, 2, 3].map((val, index) => {
            return (
              <CategoryBox
                key={index}
                view={view}
                link=""
                skeletonOnly={true}
              />
            );
          })}
      </Box>
    </div>
  );
};

export default CategoriesContainer;
