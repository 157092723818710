import React, {useState, useMemo} from "react";
import AddProductContext from "./AddProductContext";

const AddProductState = (props) => {
  const initState = {
    categories: [],
    parentCategory: {id: "NONE", name: "None"},
    id: "",
    title: "",
    searchTerms: "",
    description: "",
    maxPrice: "",
    listPrice: "",
    unitsInStock: "",
    netQuantity: "",
    unitType: "",
    images: [],
    visible: true,
    createdAt: null, // Update in other files
    flipkartURL: "",
    amazonURL: "",
    tata1mgURL: "",
  };
  const [state, setState] = useState(initState);
  // useEffect(() => {
  //   console.log("Product state:", state);
  // }, [state]);

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [state]
  );
  return (
    <AddProductContext.Provider value={value}>
      {props.children}
    </AddProductContext.Provider>
  );
};

export default AddProductState;
