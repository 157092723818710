import {Box, Container, CssBaseline, Typography} from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CustomButton from "../../common/Button/CustomButton";
import {useNavigate} from "react-router-dom";
import "./thank-animate.css";
const ThankYouForOrder = () => {
  const navigate = useNavigate();
  return (
    <Container maxWidth="sm" align="center">
      <CssBaseline />

      <Typography
        variant="h3"
        align="center"
        sx={{mt: 4}}
        className="animate-charcter"
      >
        Order Placed !
      </Typography>
      <Box sx={{display: "flex", justifyContent: "center", mt: 4}}>
        <CheckIcon fontSize="large" sx={{fontSize: 80}} color="success" />
      </Box>
      <Box sx={{mt: 4}}>
        <Typography variant="h4" align="center" sx={{mt: 4}} color="success">
          Thank you for your purchase
        </Typography>
        <Typography variant="body1" align="center" sx={{mt: 2}}>
          We will send you an email with details of your order and shipping
          instructions. If you have any questions, please contact us at
          <b style={{fontWeight: 500}}> support@inproveda.com.</b>
        </Typography>
      </Box>
      <Box sx={{mt: 4}}>
        <CustomButton
          variant="outlined"
          onClick={() => navigate("/userOrders")}
        >
          Go to Orders Page
        </CustomButton>
      </Box>
    </Container>
  );
};

export default ThankYouForOrder;
