import React, {useState, useContext, useEffect} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {drawerStyles} from "./styles";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";

import useTheme from "@mui/material/styles/useTheme";
import SearchBar from "../../../common/SearchBar";
import {Link, useLocation, useNavigate} from "react-router-dom";

import AuthAPI from "../../../../api/firebase/AuthAPI";
import ColorModeContext from "../../../../context/colorMode/ColorModeContext";
import CustomButton from "../../../common/Button/CustomButton";
import UserProfileContext from "../../../../context/userProfile/UserProfileContext";
import Modal from "@mui/material/Modal";
import {MyList} from "./styledComponents";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import Badge from "@mui/material/Badge";
import LoginPage from "../../../../pages/authentication/login";
import CloseIcon from "@mui/icons-material/Close";
import AllCategoriesContext from "../../../../context/allCategories/AllCategoriesContext";
import CustomMenu from "./CustomMenu";
import CartItemsContext from "../../../../context/cartItems/CartItemsContext";
import ProductsAPI from "../../../../api/firebase/ProductsAPI";
import LikedItemsContext from "../../../../context/likedItems/LikedItemsContext";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import {Logout} from "@mui/icons-material";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SearchResultsBox from "./SearchResultsBox";
import ScrollToColor from "./ScrollToColor";
import SaleBanner from "../SaleBanner";
import logo from "../../../../assets/images/inproveda-logo.png";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
const drawerWidth = 240;
const basePath = "/collections";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs: "100%", sm: "auto"},
  height: {xs: "100%", sm: "auto"},
  bgcolor: "background.paper",
  boxShadow: 24,
  p: {
    xs: 1,
  },
  borderRadius: "5px",
};

const DrawerAppBar = (props) => {
  const location = useLocation();
  const {pathname} = location;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAccountMenu = Boolean(anchorEl);
  const handleClickAccountMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAccountMenu = () => {
    setAnchorEl(null);
  };
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const handleOpen = () => {
    if (mobileOpen) {
      handleDrawerToggle();
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function onSearchTextChange(e) {
    setSearchText(e.currentTarget.value);
  }
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const userProfileContext = useContext(UserProfileContext);
  const allCategoriesContext = useContext(AllCategoriesContext);
  // console.log("categoriesArr:", categoriesArr);
  const currentUser = userProfileContext.state;
  // console.log("DrawerAppBar currentUser:", currentUser);
  const {window} = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const authAPI = new AuthAPI();
  const cartItemsContext = useContext(CartItemsContext);
  const likedItemsContext = useContext(LikedItemsContext);
  let cartItemsArray = cartItemsContext.state;

  //console.log("cartItemsArray:", cartItemsArray);

  const [totalCartItems, setTotalCartItems] = useState(0);
  const [totalLikedItems, setTotalLikedItems] = useState(0);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const cancelSearchHandle = () => {
    setSearchText("");
  };
  useEffect(() => {
    // Fetch Categories
    const productAPI = new ProductsAPI();
    const resCategories = productAPI.getCategories();
    resCategories.then((resData) => {
      if (!resData) {
        return;
      }
      // console.log("Done fetching all categories: ", resData.data);
      allCategoriesContext.setState(resData.data);
    });
  }, []);

  useEffect(() => {
    if (userProfileContext.state && userProfileContext.state.likedItems) {
      // console.log(
      //   "userProfileContext.state.likedItems in drawer:",
      //   userProfileContext.state.likedItems
      // );
      likedItemsContext.setState(userProfileContext.state.likedItems);
    }
    if (userProfileContext.state && userProfileContext.state.cartItems) {
      // console.log(
      //   "userProfileContext.state.cartItems in drawer:",
      //   userProfileContext.state.cartItems
      // );
      cartItemsContext.setState(userProfileContext.state.cartItems);
    }
  }, [userProfileContext.state]);

  useEffect(() => {
    const tempCategoriesArr = allCategoriesContext.state;
    const menuForNavbar = tempCategoriesArr.filter((item) => {
      return item.showInNavbar;
    });
    const nestedMenu = buildNestedMenu(menuForNavbar);
    // console.log("nestedMenu:", nestedMenu);
    setMenuItems(nestedMenu);
  }, [allCategoriesContext.state]);

  useEffect(() => {
    if (cartItemsArray !== null) {
      const totItems = cartItemsArray.reduce(
        (n, {quantity}) => n + quantity,
        0
      );
      setTotalCartItems(totItems);
      // console.log("totItems:", totItems);
    } else {
      setTotalCartItems(0);
    }
  }, [cartItemsArray]);

  useEffect(() => {
    if (likedItemsContext.state !== null) {
      const totItems = likedItemsContext.state.length;
      setTotalLikedItems(totItems);
    } else {
      setTotalLikedItems(0);
    }
    // console.log("totItems:", totItems);
  }, [likedItemsContext.state]);

  function buildNestedMenu(items) {
    let menu = [];
    const itemMap = {};
    // Create a mapping of item IDs to items with empty submenu arrays
    for (const item of items) {
      itemMap[item.id] = {
        id: item.id,
        title: item.name,
        link: `${basePath}/${item.id}`,
        parentCategory: item.parentCategory,
        submenu: [],
      };
    }
    // Iterate through the items, adding each item to the submenu array of its parent item
    for (const id in itemMap) {
      const item = itemMap[id];
      if (item.parentCategory.id !== "None") {
        if (itemMap[item.parentCategory.id] === undefined) {
          continue;
        }
        itemMap[item.parentCategory.id].submenu.push(item);
      } else {
        // If the item has no parent, add it to the top-level menu
        menu.push(item);
      }
    }
    return menu;
  }

  const handleLogout = async () => {
    try {
      await authAPI
        .signoutUser()
        .then(() => {
          userProfileContext.setState(null);
          likedItemsContext.setState(null);
          cartItemsContext.setState(null);
          localStorage.clear();
          // console.log(
          //   "Sign out success: mobileOpen=",
          //   mobileOpen,
          //   " and openAccountMenu=",
          //   openAccountMenu
          // );
          if (mobileOpen) handleDrawerToggle();
          if (openAccountMenu) handleCloseAccountMenu();
          navigate("/");
        })
        .catch((err) => {
          console.log("Error in SignOut:", err);
        });
    } catch (ex) {
      console.log("Error sign out:", ex);
    }
  };

  /// For Mobile Drawer Container
  const drawer = (
    <div>
      <Box sx={drawerStyles.topBox}>
        {/* <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 1,
            p: 0,
          }}
        >
          <IconButton
            sx={{
              width: "100%",
              "&:hover": {
                transform: "scale(1.02)",
                background: "none",
                transition: "all 0s ease",
              },
              borderRadius: 0,
            }}
            onClick={colorModeContext.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <>
                <LightModeIcon />
                <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                  Light mode
                </Typography>
              </>
            ) : (
              <>
                <Brightness3Icon />
                <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                  Dark mode
                </Typography>
              </>
            )}
          </IconButton>
        </Box>
        <Divider sx={drawerStyles.divider} /> */}
        <Typography variant="h5" component="div" sx={{margin: "0.5rem"}}>
          <Link to="/">{process.env.REACT_APP_NAME}</Link>
        </Typography>
        <Divider sx={drawerStyles.divider} />

        <Box sx={{marginTop: "10px"}}>
          <Avatar
            src={
              currentUser !== null
                ? currentUser.photoURL
                : "/static/images/avatar/1.jpg"
            }
            sx={drawerStyles.avatar}
          />
          <span>{currentUser !== null ? currentUser.displayName : ""}</span>
          {/* Link to admin */}
          {currentUser &&
            currentUser.role &&
            currentUser.role.includes("Admin") && (
              <>
                <Link to="/admin">
                  <CustomButton
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    sx={drawerStyles.logoutButton}
                  >
                    Go to Admin Panel
                  </CustomButton>
                </Link>
              </>
            )}
          {currentUser !== null ? (
            <CustomButton
              color="secondary"
              variant="contained"
              fullWidth
              sx={drawerStyles.logoutButton}
              onClick={handleLogout}
            >
              Logout
            </CustomButton>
          ) : (
            <CustomButton
              color="secondary"
              variant="contained"
              fullWidth
              sx={drawerStyles.logoutButton}
              onClick={handleOpen}
            >
              Sign in
            </CustomButton>
          )}
        </Box>
      </Box>

      <Divider />
      <Box>
        <div className="nav-area-mobile">
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return (
              <CustomMenu
                mobile={true}
                items={menu}
                key={index}
                depthLevel={depthLevel}
                onHandleToggle={handleDrawerToggle}
              ></CustomMenu>
            );
          })}
        </div>
      </Box>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <SaleBanner isMobile={mobileScreen} />
      <Box
        sx={{display: "flex"}}
        // style={{
        //   marginBottom:
        //     pathname === "/" || pathname === "/userOrders" || mobileScreen
        //       ? "0"
        //       : "1.5rem",
        // }}
      >
        <CssBaseline />
        <ScrollToColor pathname={pathname} isMobile={mobileScreen}>
          <AppBar
            // position={pathname === "/" && !mobileScreen ? "fixed" : "static"}
            position="static"
            component="nav"
            color="transparent"
            sx={{padding: "1rem"}}
          >
            <Toolbar variant={mobileScreen ? "dense" : "regular"}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{mr: 0, display: {sm: "none"}}}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{flexGrow: 1}}>
                <Typography variant="h5" component="div">
                  <Link to="/" className="logo-link">
                    <img
                      src={logo}
                      alt={process.env.REACT_APP_NAME + " logo"}
                      loading="lazy"
                      style={{height: "inherit"}}
                    />
                    {/* {process.env.REACT_APP_NAME} */}
                  </Link>
                </Typography>
              </Box>
              {/* <Box sx={{ flexGrow: 0 }}></Box> */}
              {!mobileScreen && (
                <Box sx={{flexGrow: 1}}>
                  <SearchBar
                    onSearchTextChange={onSearchTextChange}
                    searchText={searchText}
                  />

                  {searchText !== "" && (
                    <SearchResultsBox
                      styles={{
                        width: "60%",
                        minHeight: "500px",
                        position: "absolute",
                        marginTop: "10px",
                        maxHeight: "90vh",
                        boxShadow: "0px 0px 5px 0px",
                        zIndex: 10,
                      }}
                      searchText={searchText}
                      cancelSearchHandle={cancelSearchHandle}
                    />
                  )}
                </Box>
              )}
              {mobileScreen && <Box sx={{flexGrow: 1}}></Box>}
              <Box
                sx={{
                  flexGrow: 1,
                  display: {xs: "none", sm: "block", padding: "0 2rem"},
                }}
              >
                <div className="nav-area">
                  {menuItems.map((menu, index) => {
                    const depthLevel = 0;
                    return (
                      <CustomMenu
                        items={menu}
                        uniqueKey={index}
                        key={index}
                        depthLevel={depthLevel}
                      ></CustomMenu>
                    );
                  })}
                </div>
              </Box>

              {/* <Box sx={{ flexGrow: 0 }}>
            <div className="action-link">
              <IconButton
                disableRipple={mobileScreen ? false : true}
                onClick={colorModeContext.toggleColorMode}
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <LightModeIcon />
                ) : (
                  <Brightness3Icon />
                )}
              </IconButton>
            </div>
          </Box> */}
              <Box sx={{flexGrow: 0}}>
                <MyList type="row">
                  {totalLikedItems > 0 && (
                    <div
                      className="drawer-appbar-action-icon action-link"
                      style={{marginRight: "5px !important"}}
                    >
                      <IconButton
                        color="inherit"
                        onClick={() => navigate("/myWishlist")}
                      >
                        <Badge
                          badgeContent={totalLikedItems}
                          max={99}
                          color="primary"
                        >
                          <FavoriteIcon />
                        </Badge>
                      </IconButton>
                    </div>
                  )}
                  <div
                    className="drawer-appbar-action-icon action-link"
                    style={{marginRight: "5px !important"}}
                  >
                    <IconButton
                      color="inherit"
                      onClick={() => navigate("/cart")}
                    >
                      <Badge
                        badgeContent={totalCartItems}
                        max={99}
                        color="primary"
                      >
                        <ShoppingCartOutlinedIcon />
                      </Badge>
                    </IconButton>
                  </div>
                  <div className="drawer-appbar-action-icon action-link">
                    {currentUser !== null && (
                      <IconButton
                        color="inherit"
                        onClick={handleClickAccountMenu}
                        aria-controls={
                          openAccountMenu ? "account-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openAccountMenu ? "true" : undefined}
                      >
                        <PersonOutlineOutlinedIcon />
                      </IconButton>
                    )}
                    {currentUser === null && (
                      <IconButton color="inherit" onClick={handleOpen}>
                        <LoginOutlinedIcon />
                      </IconButton>
                    )}
                  </div>
                </MyList>
              </Box>
            </Toolbar>
            {mobileScreen && (
              <Toolbar variant="dense">
                <Box sx={{flexGrow: 1}}>
                  <SearchBar
                    onSearchTextChange={onSearchTextChange}
                    searchText={searchText}
                  />
                  {searchText !== "" && (
                    <SearchResultsBox
                      styles={{
                        width: "91%",
                        minHeight: "500px",
                        position: "absolute",
                        marginTop: "5px",
                        maxHeight: "90vh",
                        boxShadow: "0px 0px 5px 0px",
                        zIndex: 10,
                      }}
                      searchText={searchText}
                      cancelSearchHandle={cancelSearchHandle}
                    />
                  )}
                </Box>
              </Toolbar>
            )}
          </AppBar>
        </ScrollToColor>

        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: {xs: "block", sm: "none"},
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {mobileScreen && (
              <IconButton sx={{}} onClick={handleClose}>
                <CloseIcon sx={{fontSize: "1.5rem", color: "darkgrey"}} />
              </IconButton>
            )}
            <LoginPage handleCloseListener={handleClose} />
          </Box>
        </Modal>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openAccountMenu}
          onClose={handleCloseAccountMenu}
          onClick={handleCloseAccountMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{horizontal: "right", vertical: "top"}}
          anchorOrigin={{horizontal: "right", vertical: "bottom"}}
        >
          {/* Link to admin */}
          {currentUser &&
            currentUser.role &&
            currentUser.role.includes("Admin") && (
              <div key="admin">
                <MenuItem onClick={handleCloseAccountMenu}>
                  <PersonOutlineOutlinedIcon sx={{mr: "10px"}} /> Profile
                </MenuItem>
                <Link to="/admin">
                  <MenuItem>
                    <AdminPanelSettingsIcon sx={{mr: "10px"}} /> Admin Panel
                    {/* <CustomButton
                  color="secondary"
                  variant="contained"
                  fullWidth
                  sx={drawerStyles.logoutButton}
                >
                  
                </CustomButton> */}
                  </MenuItem>
                </Link>
                <Link to="/userAddresses">
                  <MenuItem onClick={handleCloseAccountMenu}>
                    <PinDropIcon sx={{mr: "10px"}} />
                    My Addresses
                  </MenuItem>
                </Link>
              </div>
            )}
          {currentUser &&
            currentUser.role &&
            !currentUser.role.includes("Admin") && (
              <div key="user">
                <Link to="/userProfile">
                  <MenuItem onClick={handleCloseAccountMenu}>
                    <PersonOutlineOutlinedIcon sx={{mr: "10px"}} /> Profile
                  </MenuItem>
                </Link>
                <Link to="/userOrders">
                  <MenuItem onClick={handleCloseAccountMenu}>
                    <ListOutlinedIcon sx={{mr: "10px"}} /> Orders
                  </MenuItem>
                </Link>
                <Link to="/userAddresses">
                  <MenuItem onClick={handleCloseAccountMenu}>
                    <PinDropIcon sx={{mr: "10px"}} />
                    My Addresses
                  </MenuItem>
                </Link>
              </div>
            )}

          <Divider />

          {/* <MenuItem onClick={handleCloseAccountMenu}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default DrawerAppBar;
