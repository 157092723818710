import {Container, Divider, Grid, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
const AppFooter = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        mt: "3rem",
        pb: "2rem",
        background: "#007d821a",
        color: "darkolivegreen",
      }}
      disableGutters
    >
      <Divider />
      <Grid
        container
        spacing={2}
        sx={{p: "1rem"}}
        justifyContent="space-around"
      >
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" className="footer-item-heading">
            Policies
          </Typography>
          <Typography variant="body2">
            <Link to="/privacy-policy" className="footer-item link">
              Privacy Policy
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link to="/refund-policy" className="footer-item link">
              Refund Policy
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link to="/shipping-policy" className="footer-item link">
              Shipping Policy
            </Link>
          </Typography>
          <Typography variant="body2">
            <Link to="/terms-and-conditions" className="footer-item link">
              Terms and Conditions
            </Link>
          </Typography>
        </Grid>

        {/* <Grid item xs={4} sm={3}>
          <Typography variant="h6" className="footer-item-heading">
            About
          </Typography>
          <Typography variant="body2">
            <Link to="/about-inproveda" className="footer-item link">
              Know Inproveda
            </Link>
          </Typography>

          <Typography variant="body2">
            <Link to="/refund-policy" className="footer-item link">
              Refund Policy
            </Link>
          </Typography>

          <Typography variant="body2">
            <Link to="/shipping-policy" className="footer-item link">
              Shipping Policy
            </Link>
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" className="footer-item-heading">
            Contact
          </Typography>
          <Typography variant="body2">
            Apeksha Lifecare Products,
            <br /> Haridwar, Uttarakhand - INDIA
          </Typography>
          <Typography variant="body2" mt="0.5rem">
            Email : info@inproveda.com
          </Typography>
          <Typography variant="body2">
            Call or Whatsapp : +91-9411174186
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography variant="h6" className="footer-item-heading">
            Follow us on
          </Typography>
          <div style={{marginTop: "1rem"}}>
            <a
              rel="nofollow"
              href="https://www.facebook.com/profile.php?id=61559031269996"
              target="_blank"
            >
              <IconButton
                aria-label="facebook"
                className="salebar-caption icon-button"
                disableTouchRipple
                disableFocusRipple
                disableRipple
              >
                <FacebookIcon
                  sx={{fontSize: 36}}
                  className="salebar-caption icon fb"
                />
              </IconButton>
            </a>
            <a
              rel="nofollow"
              href="https://www.instagram.com/inprovedahealthcare/"
              target="_blank"
            >
              <IconButton
                aria-label="instagram"
                className="salebar-caption icon-button"
                disableTouchRipple
                disableFocusRipple
                disableRipple
              >
                <InstagramIcon
                  sx={{fontSize: 36}}
                  className="salebar-caption icon insta"
                />
              </IconButton>
            </a>
            <a
              rel="nofollow"
              href="https://www.linkedin.com/in/inprovedahealthcare-products-b36a6037/"
              target="_blank"
            >
              <IconButton
                aria-label="linkedin"
                className="salebar-caption icon-button"
                disableTouchRipple
                disableFocusRipple
                disableRipple
              >
                <LinkedInIcon
                  sx={{fontSize: 36}}
                  className="salebar-caption icon linkedin"
                />
              </IconButton>
            </a>
            <a
              rel="nofollow"
              href="https://api.whatsapp.com/send/?phone=919411174186"
              target="_blank"
            >
              <IconButton
                aria-label="whatsapp"
                className="salebar-caption icon-button"
                disableTouchRipple
                disableFocusRipple
                disableRipple
              >
                <WhatsAppIcon
                  sx={{fontSize: 36}}
                  className="salebar-caption icon whatsapp"
                />
              </IconButton>
            </a>
          </div>
        </Grid>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Typography variant="body2" sx={{textAlign: "center", mt: "2rem"}}>
            Webapp developed by&nbsp;
            <br />
            <a
              className="softaim-link"
              target="_blank"
              href="https://softaim.in"
              rel="external"
              hrefLang="en"
            >
              Softaim Technologies Pvt. Ltd.
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppFooter;
